import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';

import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileError, setMobileError] = useState(null);
  const [passwordError, setPasswordError]=useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Create a new instance of FormData
    const formData = new FormData(e.target);
  
    try {
      // Make the API call using Axios
      const response = await axios.post('https://admin.manavlokvikas.com/studentLogin', formData);
  
      // Handle the response here (e.g., show success message)
      console.log(response.data);
      if(response.data.status===true){
        setLoading(false);
      
        
        const Token=response.data.token;
        localStorage.setItem('key', Token);
        navigate(`/dashboard/app`)
        // open otp page using useNavigate
      }
      if(response.data.status==='mobileerror'){
        setLoading(false);
        setMobileError('अवैध मोबाईल नंबर')

        
      }
      if(response.data.status==='passerror'){
        setLoading(false);
        setPasswordError('चुकीचा पासवर्ड')

        
      }
    } catch (error) {
      setLoading(false);
      
      // Handle errors here (e.g., show error message)
      console.error(error);
    }
  };
  const handleMobileChange = (event) => {
    // Reset mobileError to null
    setMobileError(null);

    // Process the mobile number
    const mobileNumber = event.target.value;
    // Perform validation logic and set mobileError if necessary
    // ...
  };
  const handlepassChange = (event) => {
    // Reset mobileError to null
    setPasswordError(null);

    // Process the mobile number
    const mobileNumber = event.target.value;
    // Perform validation logic and set mobileError if necessary
    // ...
  };



  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <TextField name="mobileno" label="रजिस्टर मोबाईल नंबर टाका" type='number' color={mobileError ? 'error' : ''}
         onChange={handleMobileChange}
         required
        />
        {mobileError && <p  style={{ textAlign: "left", fontSize:"0.75rem", color:"red", marginTop:"-1px" }}>{mobileError}</p>}
        <TextField
          name="password"
          label="पासवर्ड टाका"
          type={showPassword ? 'text' : 'password'}
          color={passwordError ? 'error' : ''}
          onChange={handlepassChange}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {passwordError && <p  style={{ textAlign: "left", fontSize:"0.75rem", color:"red", marginTop:"-1px" }}>{passwordError}</p>}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
        पासवर्ड विसरलात ?
        </Link>
      </Stack>

      <LoadingButton fullWidth size="large" loading={loading} loadingIndicator="Login...."  type="submit" variant="contained" >
      लॉगिन
      </LoadingButton>
    </form>
  );
}
