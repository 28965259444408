import React,{useState, useEffect} from 'react'
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
// @mui
import { Grid, Button, Container, Stack, Typography, TextField , Avatar} from '@mui/material';

const ProfileSection = () => {
    const [account, setAccount] = useState([]);
    const navigate = useNavigate();
    const userToken=localStorage.getItem('key');

    useEffect(() => {
      const fetchUserInfo = async () => {
        try {
          const response = await fetch('https://admin.manavlokvikas.com/studentPaymentInfo', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              reqToken: userToken,
            }),
          });
  
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            setAccount(data);
            if (data.status === 'error') {
                alert("logging out");
                localStorage.removeItem('key');
              navigate(`/login`);
            }
          }
        } catch (error) {
          // Handle network errors
          console.log('Error:', error.message);
        }
      };
  
      fetchUserInfo();
    }, []);
  return (
    <>
    <Helmet>
        <title> Profile Section </title>
      </Helmet>
      <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
      <Grid container spacing={2}>
      <Grid item xs={12}>
        <Avatar alt="Student Photo" src={`${'https://admin.manavlokvikas.com/'}${account.photo}`} sx={{ width: 120, height: 120 }}/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Personal Information</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Name"
          value={account.name || ''}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Date of Birth"
          value={account.dob || ''}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Mobile Number"
          value={account.mobileno || ''}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Email"
          value={account.email || ''}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Address Information</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Address"
          value={account.address || ''}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="District"
          value={account.dist || ''}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Taluka"
          value={account.taluka || ''}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Additional Information</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Group"
          value={account.group || ''}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="College/School Name"
          value={account.collegeSchoolname || ''}
          fullWidth
          disabled
        />
      </Grid>
    
    </Grid>
          </Stack>
          <div style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
        <Button>Update</Button>
        </div>
      </Container>
    
    </>
  )
}

export default ProfileSection